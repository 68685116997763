<template>
  <div class="container">
    <BaseHeader
      title="Cadastrar novo agendamento"
      :navigation="navigation"
    ></BaseHeader>

    <div v-if="!loading" data-anima="top">
      <div v-if="step === 1" class="container-card" data-anima="top">
        <div
          class="card"
          @click="type_meeting = 'Particular'"
          :class="{ 'card-active': type_meeting === 'Particular' }"
        >
          <img
            class="icon-people"
            src="@/assets/icons/calendar/particular.svg"
            alt="particular"
          />
          <div class="text-card">
            <h1>Particular</h1>
            <h2>Um organizador com um convidado</h2>
          </div>
          <img
            class="icon-chevron"
            src="@/assets/icons/calendar/chevron.svg"
            alt="chevron"
          />
        </div>
        <!-- <div
          class="card"
          @click="type_meeting = 'Grupo'"
          :class="{ 'card-active': type_meeting === 'Grupo' }"
        >
          <img
            class="icon-people"
            src="@/assets/icons/calendar/group.svg"
            alt="group"
          />
          <div class="text-card">
            <h1>Grupo</h1>
            <h2>Um organizador com grupo de convidado</h2>
          </div>
          <img
            class="icon-chevron"
            src="@/assets/icons/calendar/chevron.svg"
            alt="chevron"
          />
        </div> -->
        <div class="container-button">
          <BaseButton v-if="step === 1" variant="primary" @click="step = 2">
            Proxima Etapa</BaseButton
          >
        </div>
      </div>

      <div v-if="step === 2" data-anima="top">
        <div class="container-grid">
          <!-- Titulo -->
          <div>
            <label for="event_title">Titulo</label>
            <input
              style="width: 100%"
              type="text"
              id="event_title"
              v-model="title"
              placeholder="Digite o titulo do evento"
            />
          </div>

          <!-- Local -->
          <div id="select-type-pixel">
            <label for="pixel_type">Selecione o local do evento</label>
            <multiselect
              v-model="selected_type_event"
              id="pixel_type"
              label="name"
              track-by="id"
              placeholder="Pesquise por um pixel"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              open-direction="bottom"
              :options="types"
              :multiple="false"
              :taggable="false"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :show-no-results="true"
              :hide-selected="false"
              :allow-empty="false"
              :searchable="false"
            >
              <span slot="noOptions">Pesquise por um local</span>
              <span slot="noResult">Oops! Nenhum local encontrado.</span>
              <template slot="option" slot-scope="{ option }">
                <div class="div-slot">
                  <img
                    v-if="option.type == 'presencial'"
                    class="icon"
                    :src="require('@/assets/icons/calendar/location.svg')"
                    alt="location"
                  />
                  <img
                    v-if="option.type == 'meet'"
                    class="icon"
                    :src="require('@/assets/icons/calendar/google-meet.svg')"
                    alt="meet"
                  />
                  <img
                    v-if="option.type == 'teams'"
                    class="icon"
                    :src="require('@/assets/icons/calendar/ms-teams.svg')"
                    alt="ms-teams"
                  />
                  <img
                    v-if="option.type == 'zoom'"
                    class="icon"
                    :src="require('@/assets/icons/calendar/zoom.svg')"
                    alt="zoom "
                  />
                  <div>
                    <span>{{ option.name }}</span>
                    <p v-if="option.type != 'presencial'">Conferência na Web</p>
                  </div>
                </div>
              </template>
              <template slot="singleLabel" slot-scope="{ option }">
                <div class="div-slot">
                  <img
                    v-if="option.type == 'presencial'"
                    class="icon"
                    :src="require('@/assets/icons/calendar/location.svg')"
                    alt="location"
                  />
                  <img
                    v-if="option.type == 'meet'"
                    class="icon"
                    :src="require('@/assets/icons/calendar/google-meet.svg')"
                    alt="meet"
                  />
                  <img
                    v-if="option.type == 'teams'"
                    class="icon"
                    :src="require('@/assets/icons/calendar/ms-teams.svg')"
                    alt="ms-teams"
                  />
                  <img
                    v-if="option.type == 'zoom'"
                    class="icon"
                    :src="require('@/assets/icons/calendar/zoom.svg')"
                    alt="zoom "
                  />

                  <span>{{ option.name }}</span>
                </div>
              </template>
            </multiselect>
          </div>

          <!-- Google -->
          <div id="select-type-pixel" class="mt-3" v-if="!google">
            <label for="pixel_type">Vincular conta do Google</label>
            <div id="googleLogin">
              <button @click="conectGoogle" id="login_button" class="btn">
                <img
                  id="google_img"
                  src="@/assets/img/icons/google.svg"
                  width="18"
                  height="15"
                />
                <span>
                  {{
                    google ? "Desconectar Google" : "Conectar com o Google"
                  }}</span
                >
              </button>
            </div>
          </div>
          <!-- Descrição/instruções -->
          <div class="mt-3">
            <label>Descrição/instruções</label>
            <b-form-textarea
              id="textarea-tag"
              rows="3"
              max-rows="6"
              placeholder="Escreva um resumo e todos os detalhes que seu convidado deve saber sobre o evento."
              v-model="descricao"
            ></b-form-textarea>
          </div>
          <!-- Link do Evento -->
          <div class="mt-2">
            <label for="link_event">Link do evento</label>
            <span style="font-size: 12px; position: relative; top: -6px"
              >agendamento.gdigital.com/{{
                $store.getters.tenantSubdomain
              }}/</span
            >
            <input
              style="width: 100%"
              type="text"
              id="link_event"
              v-model="link"
              placeholder="Digite o link do evento"
            />
          </div>
          <!-- Limite de convidados em um mesmo horário -->
          <div class="mt-2" v-if="type_meeting == 'Grupo'">
            <label for="limit_user"
              >Limite de convidados em um mesmo horário</label
            >
            <input
              type="number"
              v-model="number_users"
              id="limit_user"
              min="1"
              max="1000"
            />
          </div>
        </div>
        <!-- Cor do Evento -->
        <div class="mt-2 config-color">
          <div cols="12" md="3" class="d-flex">
            <div class="color" :style="'background:' + color.background + ';'">
              <span :style="'background:' + color.color + ';'"></span>
            </div>
            <p class="title-config ml-3">Cor selecionada</p>
          </div>
          <div class="d-flex gap">
            <div
              class="color anime"
              @click="selectColor(color)"
              v-for="color in colors"
              :key="color.id"
              :style="'background:' + color.background + ';'"
            >
              <span :style="'background:' + color.color + ';'"></span>
            </div>
          </div>
        </div>
        <div class="container-button">
          <!-- <BaseButton
            v-if="step >= 2"
            variant="link-info"
            class="mr-4"
            @click="step--"
          >
            Voltar
          </BaseButton> -->
          <BaseButton
            v-if="step === 2"
            variant="primary"
            @click="validNextStep"
          >
            Proxima Etapa</BaseButton
          >
        </div>
      </div>

      <div v-if="step === 3" data-anima="top">
        <div class="container-grid">
          <!-- Titulo -->
          <div>
            <label for="event_title2"
              >Defina o intervalo de datas quando poderá aceitar
              reuniões.</label
            >
            <div class="grid-flex-select2">
              <b-form-radio
                v-model="selected_days"
                name="some-radios"
                value="days"
              ></b-form-radio>
              <input
                v-model="digits_days"
                style="width: 100%; margin: 0 !important"
                type="text"
                id="event_title2"
                placeholder="Ex: 60"
                :disabled="selected_days != 'days'"
              />
              <multiselect
                v-model="selected_days_sem"
                :options="selected_days_sem_array"
                label="text"
                class="select-pipe"
                track-by="value"
                placeholder=""
                selectLabel=""
                deselectLabel=""
                selectedLabel=""
                :allow-empty="false"
                :disabled="selected_days != 'days'"
                :searchable="false"
              >
              </multiselect>
              <span>no futuro</span>
            </div>

            <div class="grid-flex-select">
              <b-form-radio
                v-model="selected_days"
                name="some-radios"
                value="interval"
              ></b-form-radio>
              <span>Em um intervalo de datas</span>
              <date-range-picker
                v-if="selected_days == 'interval'"
                ref="picker"
                opens="right"
                :locale-data="localeDate"
                :showWeekNumbers="false"
                :showDropdowns="true"
                :autoApply="false"
                :min-date="minDate"
                :ranges="dateRanges"
                v-model="dateRange"
                style="width: 180px"
              >
                <template
                  v-if="dateRange.startDate && dateRange.endDate"
                  v-slot:input="picker"
                >
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
                <template v-else v-slot:input> Selecione as datas </template>
              </date-range-picker>
            </div>

            <div class="grid-flex-select">
              <b-form-radio
                v-model="selected_days"
                name="some-radios"
                value="always"
              ></b-form-radio>
              <span>Indefinidamente no futuro</span>
            </div>
          </div>

          <div
            style="display: flex; justify-content: space-between; z-index: 2"
          >
            <div>
              <label for="event_title3"
                >Defina o tempo/duração de cada reunião.</label
              >
              <multiselect
                v-model="selected_time"
                :options="selected_time_array"
                label="text"
                class="select-pipe"
                track-by="value"
                placeholder=""
                selectLabel=""
                deselectLabel=""
                selectedLabel=""
                :allow-empty="false"
                :searchable="false"
              >
              </multiselect>
            </div>

            <div v-if="selected_time.value == 'custom'">
              <label for="event_title4"
                >Defina o tempo/duração de cada reunião.</label
              >
              <input
                v-model="custom_time"
                style="width: 100%; margin: 0 !important"
                type="number"
                id="event_title4"
                placeholder="Ex: 120 min"
                min="1"
                max="480"
              />
            </div>
          </div>

          <div class="mt-2">
            <label for="event_title5">Disponibilidade da agenda</label>
            <div class="container-days">
              <div
                v-for="(week, index) in component_days"
                :key="index"
                class="content-days"
              >
                <div class="grid-content">
                  <div class="flex-content">
                    <b-form-checkbox
                      :id="`checkbox${index}`"
                      v-model="week.checked"
                      :name="`checkbox${index}`"
                      :value="true"
                      :unchecked-value="false"
                    >
                    </b-form-checkbox>
                    <h1 class="day">{{ week.day }}</h1>
                  </div>
                  <div>
                    <div
                      v-if="week.checked === true"
                      style="display: grid; gap: 5px"
                    >
                      <div
                        v-for="(times, idx) in week.times"
                        :key="idx"
                        class="flex-content"
                        style="gap: 10px"
                      >
                        <multiselect
                          v-model="times.time1"
                          :options="array_time"
                          label="id"
                          track-by="value"
                          placeholder=""
                          selectLabel=""
                          deselectLabel=""
                          selectedLabel=""
                          :allow-empty="false"
                          :searchable="false"
                        >
                        </multiselect>
                        -
                        <multiselect
                          v-model="times.time2"
                          :options="array_time"
                          label="id"
                          track-by="value"
                          placeholder=""
                          selectLabel=""
                          deselectLabel=""
                          selectedLabel=""
                          :allow-empty="false"
                          :searchable="false"
                        >
                        </multiselect>
                        <img
                          class="icon"
                          src="@/assets/icons/calendar/trash.svg"
                          alt="trash"
                          @click="removeTime(week.times, idx)"
                        />
                      </div>
                    </div>
                    <span v-else>Indisponível</span>
                  </div>
                </div>
                <div class="flex-content" style="gap: 15px">
                  <img
                    class="icon"
                    src="@/assets/icons/calendar/plus.svg"
                    alt="add"
                    @click="addNewTime(week.times, index)"
                  />
                  <div style="position: relative">
                    <img
                      class="icon"
                      src="@/assets/icons/calendar/copy.svg"
                      alt="copy"
                      @click="validCheckWeek(week)"
                    />

                    <div
                      @mouseleave="leaveDropdownWeek(week)"
                      v-show="week.dropdown"
                      class="dropDownCard"
                    >
                      <span class="copy_time">Copiar horários</span>
                      <div
                        v-for="(week2, index2) in component_days"
                        :key="index2"
                      >
                        <div class="grid-content">
                          {{ week2.day }}
                          <b-form-checkbox
                            :id="`weeksChecks${index2}`"
                            v-model="week2.checked_copy"
                            :name="`weeksChecks${index2}`"
                            :value="true"
                            :unchecked-value="false"
                            :disabled="index === index2"
                          >
                          </b-form-checkbox>
                        </div>
                      </div>
                      <BaseButton variant="info" @click="applyCheckWeek(week)">
                        Aplicar
                      </BaseButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-2">
            <label for="final_title"
              >Deseja adicionar tempo antes ou depois dos seus eventos?</label
            >
            <div class="grid-checks">
              <div class="mt-2" style="display: grid; gap: 5px">
                <b-form-checkbox
                  id="antes"
                  v-model="time_event1"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                >
                  Antes do evento
                </b-form-checkbox>
                <multiselect
                  :disabled="time_event1 == false"
                  v-model="selected_time_event_array1"
                  :options="time_event_array"
                  label="value"
                  track-by="id"
                  placeholder=""
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  :allow-empty="false"
                  :searchable="false"
                >
                </multiselect>
              </div>
              <div class="mt-2" style="display: grid; gap: 5px">
                <b-form-checkbox
                  id="depois"
                  v-model="time_event2"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                >
                  Depois do evento
                </b-form-checkbox>
                <multiselect
                  :disabled="time_event2 == false"
                  v-model="selected_time_event_array2"
                  :options="time_event_array"
                  label="value"
                  track-by="id"
                  placeholder=""
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  :allow-empty="false"
                  :searchable="false"
                >
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="container-button">
          <BaseButton
            v-if="step == 3"
            variant="link-info"
            class="mr-4"
            @click="step = 2"
          >
            Voltar
          </BaseButton>
          <BaseButton
            v-if="step === 3"
            variant="primary"
            @click="validOnSubmit"
          >
            Salvar Evento</BaseButton
          >
        </div>
      </div>
    </div>

    <!-- SE EXISTIR LOADING-->
    <div class="d-flex justify-content-center" v-if="loading" data-anima="top">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </div>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import Multiselect from "vue-multiselect";
import moment from "moment-timezone";
//
import CalendarsService from "@/services/resources/CalendarService";
const serviceCalendar = CalendarsService.build();
//
import FormService from "@/services/resources/FormService";
const serviceForm = FormService.build();
//

import GoogleService from "@/services/resources/GoogleService";
const serviceGoogle = GoogleService.build();

export default {
  components: {
    Multiselect,
    DateRangePicker,
  },
  data() {
    return {
      google: false,
      navigation: [
        { link: "Aplicativos", to: "/dynamicRoute/apps" },
        { link: "Agendamentos", to: "/dynamicRoute/apps/agendamentos" },
        {
          link: this.$route.params.id ? "Editar" : "Criar",
          to: this.$route.fullPath,
        },
      ],
      number_users: null,
      search: "",
      type_meeting: "Particular",
      time_event1: false,
      time_event2: false,
      stops: [],
      selected_time_event_array1: { id: "15", value: "15 min" },
      selected_time_event_array2: { id: "15", value: "15 min" },
      time_event_array: [
        { id: "5", value: "5 min" },
        { id: "10", value: "10 min" },
        { id: "15", value: "15 min" },
        { id: "30", value: "30 min" },
        { id: "45", value: "45 min" },
        { id: "1", value: "1h" },
        { id: "1:30", value: "1h 30 min" },
        { id: "2", value: "2h" },
        { id: "2:30", value: "2h 30 min" },
        { id: "3", value: "3h" },
      ],
      array_time: [
        { id: "00:00", value: "00:00" },
        { id: "00:15", value: "00:15" },
        { id: "00:30", value: "00:30" },
        { id: "00:45", value: "00:45" },
        { id: "01:00", value: "01:00" },
        { id: "01:15", value: "01:15" },
        { id: "01:30", value: "01:30" },
        { id: "01:45", value: "01:45" },
        { id: "02:00", value: "02:00" },
        { id: "02:15", value: "02:15" },
        { id: "02:30", value: "02:30" },
        { id: "02:45", value: "02:45" },
        { id: "03:00", value: "03:00" },
        { id: "03:15", value: "03:15" },
        { id: "03:30", value: "03:30" },
        { id: "03:45", value: "03:45" },
        { id: "04:00", value: "04:00" },
        { id: "04:15", value: "04:15" },
        { id: "04:30", value: "04:30" },
        { id: "04:45", value: "04:45" },
        { id: "05:00", value: "05:00" },
        { id: "05:15", value: "05:15" },
        { id: "05:30", value: "05:30" },
        { id: "05:45", value: "05:45" },
        { id: "06:00", value: "06:00" },
        { id: "06:15", value: "06:15" },
        { id: "06:30", value: "06:30" },
        { id: "06:45", value: "06:45" },
        { id: "07:00", value: "07:00" },
        { id: "07:15", value: "07:15" },
        { id: "07:30", value: "07:30" },
        { id: "07:45", value: "07:45" },
        { id: "08:00", value: "08:00" },
        { id: "08:15", value: "08:15" },
        { id: "08:30", value: "08:30" },
        { id: "08:45", value: "08:45" },
        { id: "09:00", value: "09:00" },
        { id: "09:15", value: "09:15" },
        { id: "09:30", value: "09:30" },
        { id: "09:45", value: "09:45" },
        { id: "10:00", value: "10:00" },
        { id: "10:15", value: "10:15" },
        { id: "10:30", value: "10:30" },
        { id: "10:45", value: "10:45" },
        { id: "11:00", value: "11:00" },
        { id: "11:15", value: "11:15" },
        { id: "11:30", value: "11:30" },
        { id: "11:45", value: "11:45" },
        { id: "12:00", value: "12:00" },
        { id: "12:15", value: "12:15" },
        { id: "12:30", value: "12:30" },
        { id: "12:45", value: "12:45" },
        { id: "13:00", value: "13:00" },
        { id: "13:15", value: "13:15" },
        { id: "13:30", value: "13:30" },
        { id: "13:45", value: "13:45" },
        { id: "14:00", value: "14:00" },
        { id: "14:15", value: "14:15" },
        { id: "14:30", value: "14:30" },
        { id: "14:45", value: "14:45" },
        { id: "15:00", value: "15:00" },
        { id: "15:15", value: "15:15" },
        { id: "15:30", value: "15:30" },
        { id: "15:45", value: "15:45" },
        { id: "16:00", value: "16:00" },
        { id: "16:15", value: "16:15" },
        { id: "16:30", value: "16:30" },
        { id: "16:45", value: "16:45" },
        { id: "17:00", value: "17:00" },
        { id: "17:15", value: "17:15" },
        { id: "17:30", value: "17:30" },
        { id: "17:45", value: "17:45" },
        { id: "18:00", value: "18:00" },
        { id: "18:15", value: "18:15" },
        { id: "18:30", value: "18:30" },
        { id: "18:45", value: "18:45" },
        { id: "19:00", value: "19:00" },
        { id: "19:15", value: "19:15" },
        { id: "19:30", value: "19:30" },
        { id: "19:45", value: "19:45" },
        { id: "20:00", value: "20:00" },
        { id: "20:15", value: "20:15" },
        { id: "20:30", value: "20:30" },
        { id: "20:45", value: "20:45" },
        { id: "21:00", value: "21:00" },
        { id: "21:15", value: "21:15" },
        { id: "21:30", value: "21:30" },
        { id: "21:45", value: "21:45" },
        { id: "22:00", value: "22:00" },
        { id: "22:15", value: "22:15" },
        { id: "22:30", value: "22:30" },
        { id: "22:45", value: "22:45" },
        { id: "23:00", value: "23:00" },
        { id: "23:15", value: "23:15" },
        { id: "23:30", value: "23:30" },
        { id: "23:45", value: "23:45" },
      ],
      component_days: [
        {
          day: "Dom",
          times: [
            {
              time1: { id: "00:00", value: "00:00" },
              time2: { id: "00:00", value: "00:00" },
            },
          ],
          checked: false,
          checked_copy: false,
          dropdown: false,
        },
        {
          day: "Seg",
          times: [
            {
              time1: { id: "00:00", value: "00:00" },
              time2: { id: "00:00", value: "00:00" },
            },
          ],
          checked: true,
          checked_copy: false,
          dropdown: false,
        },
        {
          day: "Ter",
          times: [
            {
              time1: { id: "00:00", value: "00:00" },
              time2: { id: "00:00", value: "00:00" },
            },
          ],
          checked: true,
          checked_copy: false,
          dropdown: false,
        },
        {
          day: "Qua",
          times: [
            {
              time1: { id: "00:00", value: "00:00" },
              time2: { id: "00:00", value: "00:00" },
            },
          ],
          checked: true,
          checked_copy: false,
          dropdown: false,
        },
        {
          day: "Qui",
          times: [
            {
              time1: { id: "00:00", value: "00:00" },
              time2: { id: "00:00", value: "00:00" },
            },
          ],
          checked: true,
          checked_copy: false,
          dropdown: false,
        },
        {
          day: "Sex",
          times: [
            {
              time1: { id: "00:00", value: "00:00" },
              time2: { id: "00:00", value: "00:00" },
            },
          ],
          checked: true,
          checked_copy: false,
          dropdown: false,
        },
        {
          day: "Sáb",
          times: [
            {
              time1: { id: "00:00", value: "00:00" },
              time2: { id: "00:00", value: "00:00" },
            },
          ],
          checked: false,
          checked_copy: false,
          dropdown: false,
        },
      ],
      digits_days: null,
      custom_time: null,
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
      dateRange: {
        startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
        endDate: moment(),
      },
      selected_days: "days",
      step: 2,
      token_api: "",
      api_conversao: false,
      navegar: false,
      conversao: false,
      visualizacao: true,
      title: "",
      link: "",
      descricao: "",
      loading_select: false,
      antiDuplicate: "",
      selected_type_event: { id: 1, name: "Google Meet", type: "meet" },
      selected_days_sem: { value: "corridos", text: "dias corridos" },
      selected_days_sem_array: [
        { value: "corridos", text: "dias corridos" },
        { value: "uteis", text: "dias úteis" },
      ],
      selected_time: { value: "15", text: "15 min" },
      selected_time_array: [
        { value: "15", text: "15 min" },
        { value: "30", text: "30 min" },
        { value: "45", text: "45 min" },
        { value: "60", text: "60 min" },
        { value: "custom", text: "Customizado em min" },
      ],
      types: [
        { id: 1, name: "Google Meet", type: "meet" },
        // { id: 2, name: "Zoom", type: "zoom" },
        // { id: 3, name: "Microsoft Teams", type: "teams" },
        // { id: 4, name: "Reunião Presencial", type: "presencial" },
      ],
      color: { color: "#3483FA", background: "rgba(52, 131, 250, 0.3)" },
      colors: [
        { color: "#2133D2", background: "rgba(33, 51, 210, 0.3)" },
        { color: "#FF0C37", background: "rgba(255, 12, 55, 0.3)" },
        { color: "#FFCB37", background: "rgba(255, 203, 55, 0.3)" },
        { color: "#2ED7EE", background: "rgba(46, 215, 238, 0.3)" },
        { color: "#5D21D2", background: "rgba(93, 33, 210, 0.3)" },
        { color: "#17191F", background: "rgba(0, 9, 22, 0.3)" },
        { color: "#3483FA", background: "rgba(52, 131, 250, 0.3)" },
        { color: "#00AF7B", background: "rgba(0, 175, 123, 0.3)" },
        { color: "#00E4A0", background: "rgba(0, 228, 160, 0.3)" },
      ],
      loading: false,
      conversion_label: "",
      // selected_form: { id: null, title: "Selecione um formulário" },
      all_forms: [],
      stop: false,
    };
  },
  computed: {
    minDate() {
      let today = new Date();
      today.setHours(4, 0, 0, 0);
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      const hours = String(today.getHours()).padStart(2, "0");
      const minutes = String(today.getMinutes()).padStart(2, "0");
      const seconds = String(today.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        Hoje: [today, today],
        // Ontem: [yesterday, yesterday],
        "Última semana": [lastWeek, today],
        "Este mês": [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        "Este ano": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        "Último mês": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "Todo período": [
          new Date(2016, 0, 1),
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        ],
      };
    },
  },
  methods: {
    conectGoogle() {
      if (this.google) {
        serviceGoogle
          .destroy({ id: "client/" + this.user_id })
          .then((resp) => {
            // console.log(resp);
            this.getGoogleIntegration();
          })
          .catch((err) => {
            // console.log(err);
            this.getGoogleIntegration();
          });
      } else {
        serviceGoogle
          .read({ id: "auth/geturl2" })
          .then((response) => {
            var url = response.url;

            url = url.replace('v2/', '');
            const popup = window.open(
              url,
              "janela",
              "width=895, height=690, top=30, left=200, scrollbars=no, status=no, toolbar=no, location=no, menubar=no, resizable=no, fullscreen=no"
            );
            var that = this;
            const popupTick = setInterval(function () {
              that.getGoogleIntegration();
              if (popup.closed) {
                that.getGoogleIntegration();
                clearInterval(popupTick);
                // console.log("window closed!");
                setTimeout(function () {
                  if (that.google === false) {
                    that.$grToast.toast("Login não realizado", {
                      title: "Minha conta",
                      variant: "danger",
                      autoHideDelay: 5000,
                      appendToast: true,
                    });
                  } else {
                    that.$grToast.toast("Login realizado com sucesso", {
                      title: "Minha conta",
                      variant: "info",
                      autoHideDelay: 5000,
                      appendToast: true,
                    });
                  }
                }, 1000);
              }
            }, 500);
          })
          .catch((err) => {
            // console.log(err);
          });
      }
    },
    getGoogleIntegration() {
      serviceGoogle
        .read({ id: "client" })
        .then((resp) => {
          // console.log(resp);
          if (resp && resp[0] && resp[0] == "cliente não encontrado") {
            this.google = false;
            return;
          }
          this.google = true;
        })
        .catch((err) => {
          // console.log(err);
        });
    },

    leaveDropdownWeek(week) {
      week.dropdown = false;
      for (let i = 0; i < this.component_days.length; i++) {
        const element = this.component_days[i];
        element.checked_copy = false;
      }
    },
    applyCheckWeek(week) {
      week.checked = true;
      for (let i = 0; i < this.component_days.length; i++) {
        const element = this.component_days[i];
        if (element.checked_copy) {
          element.checked = true;
          element.times = week.times;
        }
        element.checked_copy = false;
      }
    },
    validCheckWeek(week) {
      week.dropdown = true;
    },
    removeTime(week, index) {
      if (week.length === 1 && index === 0) {
        this.$grToast.toast("Mínimo de um horário", {
          title: "Calendário",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      week.splice(index, 1);
    },
    addNewTime(week, index) {
      week.push({
        time1: { id: "00:00", value: "00:00" },
        time2: { id: "00:00", value: "00:00" },
      });
    },
    validOnSubmit() {
      if (this.selected_days == "days") {
        if (!this.digits_days || this.digits_days == null) {
          this.$grToast.toast(
            "Intervalo de dias necessário para o calendário",
            {
              title: "Calendário",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          return;
        }
      }
      if (this.selected_days == "interval") {
        if (!this.dateRange.startDate || !this.dateRange.endDate) {
          this.$grToast.toast(
            "Intervalo de datas necessário para o calendário",
            {
              title: "Calendário",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          return;
        }
      }
      if (this.selected_time.value == "custom") {
        if (!this.custom_time || this.custom_time == null) {
          this.$grToast.toast("Defina o tempo do calendário", {
            title: "Calendário",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
        }
      }
      this.onSubmit();
    },
    validNextStep() {
      if (!this.title) {
        this.$grToast.toast("Título necessário para o calendário", {
          title: "Calendário",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      if (!this.selected_type_event || this.selected_type_event.id == null) {
        this.$grToast.toast("Selecione o local do calendário", {
          title: "Calendário",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }

      if (!this.link) {
        this.$grToast.toast("Insira um link para o calendário", {
          title: "Calendário",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }

      if (this.type_meeting == "Grupo") {
        if (this.number_users == null || !this.number_users) {
          this.$grToast.toast(
            "Limite de convidados necessário para o calendário",
            {
              title: "Calendário",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          return;
        }
      }
      this.step = 3;
    },
    selectColor(data) {
      this.color = data;
    },
    onSubmit() {
      this.loading = true;

      var data = {
        // SEND
        name: this.title,
        type: this.selected_type_event.type,
        description: this.descricao,
        link: this.link,
        color: JSON.stringify(this.color),
        // form_id sempre 0 pro usuario selecionar na pagina -->
        form_id: 0,
        start_data: "2023-08-25T17:29:30.908Z",
        end_data: "2023-08-26T17:29:30.908Z",
        // METAS
        duration: JSON.stringify(this.selected_time),
        custom_time: this.custom_time,
        schedule: JSON.stringify(this.component_days),
        time_before: this.time_event1,
        selected_time_before: JSON.stringify(this.selected_time_event_array1),
        time_after: this.time_event2,
        selected_time_after: JSON.stringify(this.selected_time_event_array2),
        type_meeting: this.type_meeting,
        number_users: this.number_users,
        check_type: this.selected_days,
        digits_days: this.digits_days,
      };

      if (this.selected_days == "days") {
        var daysToAdd = parseInt(this.digits_days);
        var startDate = new Date();

        if (this.selected_days_sem.value === "uteis") {
          var remainingDays = daysToAdd;
          var currentDate = new Date(startDate);

          while (remainingDays > 0) {
            currentDate.setDate(currentDate.getDate() + 1);
            if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
              remainingDays--;
            }
          }

          var endDate = currentDate;
        } else {
          var endDate = new Date(startDate);
          endDate.setDate(endDate.getDate() + daysToAdd);
        }

        data.start_data = startDate.toISOString();
        data.end_data = endDate.toISOString();
      } else if (this.selected_days == "always") {
        var today = new Date();
        var endData = new Date(today);
        endData.setFullYear(endData.getFullYear() + 100);

        data.start_data = today.toISOString();
        data.end_data = endData.toISOString();
      } else if (this.selected_days == "interval") {
        data.start_data = this.dateRange.startDate;
        data.end_data = this.dateRange.endDate;
      }
      if (!this.$route.params.id) {
        serviceCalendar
          .create(data)
          .then((resp) => {
            this.$grToast.toast("Calendário salvo com sucesso", {
              title: "Calendário",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });

            this.$router.push({ name: "Agendamentos" });
          })
          .catch((error) => {
            // console.log(error);
            // this.$grToast.toast("Erro ao criar calendario", {
            //   title: "Calendário",
            //   variant: "danger",
            //   autoHideDelay: 5000,
            //   appendToast: true,
            // });
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        data.id = this.$route.params.id;
        serviceCalendar
          .update(data)
          .then((resp) => {
            this.$grToast.toast("Calendário salvo com sucesso", {
              title: "Calendário",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });

            this.$router.push({ name: "Agendamentos" });
          })
          .catch((error) => {
            // console.log(error);
            // this.$grToast.toast("Erro ao criar calendario", {
            //   title: "Calendário",
            //   variant: "danger",
            //   autoHideDelay: 5000,
            //   appendToast: true,
            // });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getMetas(metas, meta, parse) {
      var meta_find = metas.find((item) => item.meta_key == meta);
      if (meta_find) {
        if (parse) {
          return JSON.parse(meta_find.meta_value);
        } else {
          return meta_find.meta_value;
        }
      } else {
        return null;
      }
    },
    getCalendar(id) {
      this.loading = true;

      serviceCalendar
        .read({ id: id })
        .then((resp) => {
          // SEND
          this.title = resp.name;
          var type = this.types.find((item) => item.type == resp.type);
          this.selected_type_event = type;
          this.descricao = resp.description;
          this.link = resp.link;
          var form = this.all_forms.find((item) => item.id == resp.form_id);
          // this.selected_form = form;
          // METAS
          this.start_data = this.getMetas(resp.metas, "start_data", false);
          this.dateRange.startDate = this.start_data;
          this.end_data = this.getMetas(resp.metas, "end_data", false);
          this.dateRange.endDate = this.end_data;
          this.color = this.getMetas(resp.metas, "color", true);
          this.selected_time = this.getMetas(resp.metas, "duration", true);
          this.custom_time = this.getMetas(resp.metas, "custom_time", false);
          this.component_days = this.getMetas(resp.metas, "schedule", true);
          var time1 = this.getMetas(resp.metas, "time_before", false);
          if (time1 == "1") {
            time1 = true;
          } else {
            time1 = false;
          }
          this.time_event1 = time1;
          this.selected_time_event_array1 = this.getMetas(
            resp.metas,
            "selected_time_before",
            true
          );
          var time2 = this.getMetas(resp.metas, "time_after", false);
          if (time2 == "1") {
            time2 = true;
          } else {
            time2 = false;
          }
          this.time_event2 = time2;
          this.selected_time_event_array2 = this.getMetas(
            resp.metas,
            "selected_time_after",
            true
          );
          this.type_meeting = this.getMetas(resp.metas, "type_meeting", false);
          this.number_users = this.getMetas(resp.metas, "number_users", false);
          this.selected_days = this.getMetas(resp.metas, "check_type", false);
          this.digits_days = this.getMetas(resp.metas, "digits_days", false);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  async mounted() {
    this.getGoogleIntegration();
    if (this.$route.params.id) {
      this.getCalendar(this.$route.params.id);
    }
  },
};
</script>

<style lang="scss" scoped>
.div-slot {
  display: flex;
  gap: 10px;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
  }
  div {
    display: grid;
    p {
      margin: 0;
      color: var(--gray03);
    }
  }
}
.container-button {
  margin: 20px 0 20px 0;
  display: flex;
  justify-content: flex-end;
}
.text-card {
  display: grid;
  gap: 10px;
  h1,
  h2 {
    margin: 0;

    font-size: 14px;
  }
  h1 {
    font-weight: 600;
    color: var(--gray01);
  }
  h2 {
    color: var(--gray03);
    font-weight: 400;
  }
}
.icon-chevron {
  width: 30px;
  height: 30px;
}
.card-active {
  border: 1px solid var(--greenn);
  box-shadow: 2px 4px 6px rgba(0, 92, 26, 0.3);
  .icon-chevron {
    animation: chevronAnimation 2s infinite ease-in-out;
  }
}
@keyframes chevronAnimation {
  0%,
  100% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
}
.card {
  border-radius: 10px;
  display: grid;
  grid-template-columns: 100px 1fr 50px;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    border: 1px solid var(--greenn);
    box-shadow: 2px 4px 6px rgba(0, 92, 26, 0.3);
  }
}
.container-card {
  display: grid;
  gap: 10px;
}
.icon-people {
  width: 100px;
  height: 100px;
  margin-left: 10px;
}
.grid-checks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.copy_time {
  font-size: 12px;
  color: var(--gray03);
  text-align: center;
  border-bottom: 1px solid var(--white-medium);
}
.dropDownCard {
  position: absolute;
  background: #fff;
  padding: 10px;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.3);
  right: -148px;
  top: 0px;
  display: grid;
  gap: 5px;
}
.icon {
  cursor: pointer;
}
.flex-content {
  display: flex;
  align-items: center;
}
.grid-content {
  display: grid;
  align-items: center;
  gap: 30px;
  grid-template-columns: 100px 1fr;
}
.day {
  font-weight: 600;
  color: var(--gray01);
  margin: 0;
  font-size: 14px;
  text-transform: uppercase;
}
.content-days {
  border-bottom: 1px solid var(--white-medium);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  span {
    color: var(--gray03);
    font-size: 13px;
  }
}
.container-days {
  display: grid;
  gap: 5px;
  border-radius: 5px;
  border: 1px solid var(--white-medium);
  padding: 20px;
}
.container-grid {
  display: grid;
  gap: 5px;
}

.config-color .color {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.config-color p {
  margin-top: 20px;
  margin-bottom: 0px;
}

.config-color .color span {
  width: 15px;
  height: 15px;
  border-radius: 100%;
}

.config-color .gap {
  gap: 0 20px;

  margin-top: 10px;
}

.title-config {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
}

.grid-flex-select {
  display: grid;
  grid-template-columns: 40px 245px 1fr;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.grid-flex-select2 {
  display: grid;
  grid-template-columns: 40px 3fr 3fr 1fr;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.login_button {
  cursor: pointer;
}

#googleLogin button,
#googleLogout button {
  transition: 0.3s ease;
  justify-content: space-between;
  align-items: center;
  padding: 12px 25px;
  border: 1px solid #e5e5e5;
  height: 50px;
  display: flex;
  font-size: 13px;
  border-radius: 10px;
  font-weight: 600;
}
#googleLogin img,
#googleLogout img {
  width: 18px;
  margin-right: 15px;
}

#googleLogin button:hover,
#googleLogout button:hover {
  background: #f2f2f2;
}

</style>
